const order = `{
  id,
  customer {
    documentType,
    documentNumber,
    verificationDigit,
    personType,
    name,
    commercialName,
    emails,
    phones,
    addresses,
    location,
    department
  },
  products {
    name,
    code,
    price,
    quantity,
    discount,
    tax,
    measureType,
    measureUnit,
    includedTax,
    taxHealthy,
    includedTaxHealthy
  },
  invoice {
    type,
    status,
    value,
    tax,
    tascode,
    document,
    CUFE,
    process,
    retries,
    customer,
    URL,
    PDF,
    ATTACHED,
    createdAt
  },
  invoiced
  canceled,
  observations,
  saler,
  paymentType,
  dueDate,
  createdAt,
  recorded,
  reteFuente,
  reteIVA,
  reteICA,
  creditNotes {
    products {
      name,
      code,
      price,
      quantity,
      discount,
      tax,
      measureType,
      measureUnit,
      includedTax,
      taxHealthy,
      includedTaxHealthy
    },
    document {
      type,
      status,
      value,
      tax,
      tascode,
      document,
      CUDE,
      process,
      retries,
      customer,
      URL,
      PDF,
      ATTACHED,
      createdAt
    },
    observations,
    recorded,
    corrected
  },
  debitNotes {
    products {
      name,
      code,
      price,
      quantity,
      discount,
      tax,
      measureType,
      measureUnit,
      includedTax,
      taxHealthy,
      includedTaxHealthy
    },
    document {
      type,
      status,
      value,
      tax,
      tascode,
      document,
      CUDE,
      process,
      retries,
      customer,
      URL,
      PDF,
      ATTACHED,
      createdAt
    },
    observations,
    recorded,
    corrected
  }
}`

export default {
  GET_DATA: `#graphql
  query getOrders($filters: Filters!, $page: Int!, $nit: String!) {
    getOrders(filters: $filters, page: $page, nit: $nit) {
      docs ${order},
      totalDocs,
      limit,
      hasPrevPage,
      hasNextPage,
      page,
      totalPages,
      prevPage,
      nextPage,
      pagingCounter
    }
  }`,

  GET_DATA_BY_DATE: `#graphql
  query getOrdersByDate($initialDate: Date!, $finalDate: Date!, $nit: String!) {
    getOrdersByDate(initialDate: $initialDate, finalDate: $finalDate, nit: $nit) ${order}
  }`,

  CREATE: `#graphql
  mutation createOrder($input: InputOrder!, $nit: String!) {
    createOrder(input: $input, nit: $nit) ${order}
  }`,

  UPDATE: `#graphql
  mutation updateOrder($input: InputUpdateOrder!, $nit: String!) {
    updateOrder(input: $input, nit: $nit) ${order}
  }`,

  RECORD: `#graphql
  mutation recordOrder($input: InputUpdateOrder!, $nit: String!) {
    recordOrder(input: $input, nit: $nit) ${order}
  }`,

  CREATE_CREDIT_NOTE: `#graphql
  mutation createCreditNote($input: InputNote!, $nit: String!, $id: String!) {
    createCreditNote(input: $input, nit: $nit, id: $id) ${order}
  }`,

  UPDATE_CREDIT_NOTE: `#graphql
  mutation updateCreditNote($input: InputNote!, $nit: String!, $id: String!, $index: Int!) {
    updateCreditNote(input: $input, nit: $nit, id: $id, index: $index) ${order}
  }`,

  RECORD_CREDIT_NOTE: `#graphql
  mutation recordCreditNote($input: InputNote!, $nit: String!, $id: String!, $index: Int!) {
    recordCreditNote(input: $input, nit: $nit, id: $id, index: $index) ${order}
  }`,

  CORRECT_CREDIT_NOTE: `#graphql
  mutation correctCreditNote($nit: String!, $orderId: String!, $index: Int!) {
    correctCreditNote(nit: $nit, orderId: $orderId, index: $index) ${order}
  }`,

  CREATE_DEBIT_NOTE: `#graphql
  mutation createDebitNote($input: InputNote!, $nit: String!, $id: String!) {
    createDebitNote(input: $input, nit: $nit, id: $id) ${order}
  }`,

  UPDATE_DEBIT_NOTE: `#graphql
  mutation updateDebitNote($input: InputNote!, $nit: String!, $id: String!, $index: Int!) {
    updateDebitNote(input: $input, nit: $nit, id: $id, index: $index) ${order}
  }`,

  RECORD_DEBIT_NOTE: `#graphql
  mutation recordDebitNote($input: InputNote!, $nit: String!, $id: String!, $index: Int!) {
    recordDebitNote(input: $input, nit: $nit, id: $id, index: $index) ${order}
  }`,

  CORRECT_DEBIT_NOTE: `#graphql
  mutation correctDebitNote($nit: String!, $orderId: String!, $index: Int!) {
    correctDebitNote(nit: $nit, orderId: $orderId, index: $index) ${order}
  }`
}
